<template>
	<div class="app" :class="activeClass">
		<div class="app__inner">
			<div class="app__grid grid-container">
				<header class="app__header">
					<div class="app__xy grid-x grid-margin-x grid-margin-y align--middle">
						<div class="app__brand large-auto cell">
							<div class="app__xy grid-x grid-margin-x align--middle">
								<div class="app__logo auto cell">
									<router-link to="/">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1557.05 628.94" height="130" width="323">
											<g id="logo-emblem">
												<path
													d="M270.33,168.28a262.26,262.26,0,0,0-69.69,22.54,258.31,258.31,0,0,0-59.76,40.26l91.9,114.3a107.3,107.3,0,0,1,27.84-11.88A101.88,101.88,0,0,1,289.51,330Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M183.4,409.31c1.12-2.56,2.3-5.08,3.59-7.55s2.64-4.87,4.06-7.2,2.93-4.61,4.5-6.8,3.2-4.34,4.92-6.38a111.11,111.11,0,0,1,10.95-11.46L103.91,278.28a249.78,249.78,0,0,0-19.36,29.66q-4.32,7.71-8,15.67T69.7,339.72c-2.08,5.43-4,10.9-5.66,16.43s-3.19,11.08-4.5,16.69a246.33,246.33,0,0,0-5.42,34.06l123.72,18.24A120.13,120.13,0,0,1,183.4,409.31Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M176.34,482.45A123.54,123.54,0,0,1,174,447.5l-119.54,5A238.26,238.26,0,0,0,103.54,575L188.8,516.5A129.18,129.18,0,0,1,176.34,482.45Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M238.57,568.19c-2.78-1.7-5.54-3.4-8.18-5.26a131.54,131.54,0,0,1-15-12,134.28,134.28,0,0,1-12.91-13.79L133.44,606.6a229.32,229.32,0,0,0,25.8,19.56,225.19,225.19,0,0,0,28,15.58C192.1,644,197,646,202,648s9.93,3.63,15,5.13a223.3,223.3,0,0,0,30.61,7.1l18-79.69a139.68,139.68,0,0,1-18.33-7.64C244.25,571.43,241.4,569.81,238.57,568.19Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M332.65,586.24a145.18,145.18,0,0,1-41,.35L289,662.83a217.46,217.46,0,0,0,60.34-10.5,215.23,215.23,0,0,0,54-26.29l-30.17-51.93A151.17,151.17,0,0,1,332.65,586.24Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M450.33,495.38c-.39.87-.75,1.75-1.15,2.62l-1.26,2.57c-.81,1.72-1.71,3.4-2.6,5.08s-1.83,3.32-2.76,5a156.16,156.16,0,0,1-44.49,49L433.81,601A207.62,207.62,0,0,0,469,554.59a202,202,0,0,0,12.1-26.05c.83-2.24,1.7-4.45,2.43-6.71s1.53-4.5,2.19-6.78l1-3.39c.32-1.14.6-2.29.9-3.44l1.75-6.85-36.73-11.26Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path d="M488.72,409.81l-24.6,4.2a166,166,0,0,1-2.62,46.56l32.81,3.64A195.41,195.41,0,0,0,488.72,409.81Z" transform="translate(-54.12 -33.89)" />
												<path
													d="M292.08,36.5c9.14,4,18.08,8.44,26.87,13.14,35.23,18.82,68.35,40.94,100.34,64.75C451,138,481.45,162.92,508.84,191.44c13.63,14.19,21.06,31.32,23.27,50.8.55,19.59-4.4,37.59-15.91,53.55-23.12,32.08-49.8,61.08-77.84,88.85-28.34,28.06-58,54.61-90.26,78.18-8.05,5.88-16.27,11.57-24.77,16.77-21.19,13-50.93-2.52-53.32-27.13-1.1-11.33,2.89-21,7.34-30.74,12-26.32,29.78-48.92,46.81-72,12-16.31,23.91-32.76,35-49.72,9.2-14.07,15.83-29.4,16.28-46.7-2.87-17.07-11.6-31.33-22.68-44-13.35-15.23-27.42-29.86-41.61-44.32-20.11-20.48-40.85-40.37-56.47-64.74-5.77-9-11.08-18-11.58-29.4C242,46.14,269.28,26.65,292.08,36.5Z"
													transform="translate(-54.12 -33.89)"
												/>
											</g>
											<g id="logo-text">
												<path
													d="M829.4,517.82h-41c-31-85-62-170.07-93.54-256.4H755l52.14,148.83,2.73.26,52.6-149.11H922.9Q876,390.09,829.4,517.82Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M1396.62,518.59c-16.48-2.6-32.75,4.15-50.55-6.17-20.32,9.37-44.59,12.4-69.29,2.78a88.93,88.93,0,0,1-43.08-34.86c-21-32.12-19-75.51,4.66-105.53,19.44-24.65,59.86-46.48,108.3-25.13,15.79-11.86,33-3.4,50-6.1Zm-53.81-87.92a32.93,32.93,0,0,0-33-33.23c-18.79-.11-33,13.9-33.27,32.73-.24,19.32,14.64,34.54,33.45,34.22A33.56,33.56,0,0,0,1342.81,430.67Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M1085.21,518.82c-16.25-3.46-32.74,4.89-49.24-6.81-20.83,9.44-44.61,12.75-69.09,3.53a88.88,88.88,0,0,1-43.54-34.27c-21.49-32-20-75.43,3.37-105.63,19.54-25.27,60.14-47.54,108.87-26,15.7-11.4,32.72-3.24,49.63-6.41Zm-53.29-87.72a33.09,33.09,0,0,0-32.67-33.66c-18.82-.27-33.33,13.61-33.78,32.3-.46,19.22,14.36,34.74,33.1,34.66A33.74,33.74,0,0,0,1031.92,431.1Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M1197.67,519.24c-13.21-1.26-25.27-1.66-37.06-3.69-20.53-3.53-33.53-16.11-38.55-36.27a102,102,0,0,1-3.31-23.85c-.27-57.71-.15-115.43-.13-173.15,0-2.17.32-4.34.56-7.42h54.9v14.56q0,72.49,0,145c0,4-.16,8.07.13,12.07.87,11.71,3.76,14.42,15.81,15.27l7.64.53Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path
													d="M1430.53,343.33c15.12,2.56,30.18-3.91,43.86,4.29l53-5.38v55.19c-5.7,0-11.33,0-17,0-18.71.11-25.74,6.93-25.89,25.92-.21,26.34-.06,52.69-.06,79v15.11h-53.92Z"
													transform="translate(-54.12 -33.89)"
												/>
												<path d="M1605.78,517.57h-52.33V344.69h52.33Z" transform="translate(-54.12 -33.89)" />
												<path
													d="M1579.88,333.61c-18.17.06-31.49-12.85-31.7-30.73a31.69,31.69,0,0,1,31-31.88c18.6-.23,32.13,13.26,32,31.87S1598.75,333.55,1579.88,333.61Z"
													transform="translate(-54.12 -33.89)"
												/>
											</g></svg
									></router-link>
								</div>
								<div class="app__burger shrink cell hide-for-large" v-if="showMenu">
									<div @click="toggleMenu()">
										<i v-if="!menu" class="far fa-chevron-circle-down"></i>
										<i v-else class="far fa-chevron-circle-up"></i>
									</div>
								</div>
							</div>
						</div>
						<nav class="app__menu large-shrink cell" :class="toggleMenuClass" v-if="showMenu">
							<ul class="app__menu__list">
								<li class="app__menu__item">
									<router-link to="/">Home</router-link>
								</li>
								<li class="app__menu__item">
									<router-link to="/news">News</router-link>
								</li>
								<li class="app__menu__item">
									<router-link to="/about">About</router-link>
								</li>
								<li class="app__menu__item">
									<router-link to="/contact">Contact</router-link>
								</li>
							</ul>
						</nav>
					</div>
				</header>
				<main class="app__main">
					<router-view />
				</main>
				<footer class="app__footer">
					<div class="app__xy grid-x grid-margin-x grid-margin-y align--middle">
						<div class="app__copyright medium-auto cell">
							<p>
								An <a href="https://www.austella.com/">Austella Ltd.</a> brand
								<img src="/img/AustellaIcon.svg" alt="Austella" height="41" width="41" style="margin-left:5px;vertical-align:middle" />
							</p>
						</div>
						<div class="app__copyright medium-shrink cell">
							<p><img src="/img/BLOCKvIcon.svg" alt="BLOCKv" height="41" width="38" style="margin-right:5px;vertical-align:middle" /> in partnership with <a href="">BLOCKv</a></p>
						</div>
						<!-- temp <nav class="app__social medium-shrink cell">
							<ul class="app__social__list">
								<li class="app__social__item">
									<a href=""><i class="fab fa-instagram"></i></a>
								</li>
								<li class="app__social__item">
									<a href=""><i class="fab fa-twitter"></i></a>
								</li>
							</ul>
						</nav>-->
					</div>
				</footer>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Valari',
	data() {
		return {
			menu: false,
			showMenu: false, //temp
		};
	},
	computed: {
		activeClass: function() {
			return 'app--' + this.$route.meta.theme;
		},
		toggleMenuClass: function() {
			if (!this.menu) {
				return 'show-for-large';
			} else {
				return '';
			}
		},
	},
	methods: {
		toggleMenu: function() {
			this.menu = !this.menu;
		},
	},
};
</script>

<style lang="scss">
@import './scss/common';
</style>
