import {createRouter, createWebHistory} from 'vue-router';
//import Home from '../views/Home';
import News from '../views/News';
import About from '../views/About';
import Contact from '../views/Contact';

import Holding from '../views/Holding';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Holding,
		meta: {
			theme: 'light',
		},
	},
	{
		path: '/news',
		name: 'News',
		component: News,
		meta: {
			theme: 'dark',
		},
	},
	{
		path: '/about',
		name: 'About',
		component: About,
		meta: {
			theme: 'light',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			theme: 'dark',
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
