<template>
	<section class="page page--landing page--contact">
		<div class="page__inner">
			<form class="form form--contact" ref="form" @submit.prevent="sendEmail">
				<div class="grid-x grid-margin-x grid-margin-y">
					<div class="large-7 cell">
						<h1>Contact</h1>
						<h2>Want to chat?</h2>
					</div>
					<div class="large-5 cell">
						<div class="grid-x grid-margin-y">
							<div class="cell">
								<input class="form__input" type="text" placeholder="Name" name="user_name" required />
							</div>
							<div class="cell">
								<input class="form__input" type="email" placeholder="Email" name="user_email" required />
							</div>
						</div>
					</div>
					<div class="cell">
						<textarea class="form__textarea" placeholder="What's your enquiry?" name="user_enquiry" required></textarea>
					</div>
					<div class="cell align--right">
						<button type="submit" class="btn btn--secondary">Submit?</button>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
	name: 'Contact',
	methods: {
		sendEmail() {
			emailjs.sendForm('service_u36hakg', 'template_a4d2f1e', this.$refs.form, 'user_qSpCYaCPLhHeiQznG31nW').then(
				result => {
					console.log('SUCCESS!', result.text);
				},
				error => {
					console.log('FAILED...', error.text);
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
h1 {
	font-size: 8.5vw;
	line-height: 0.8;
	text-transform: uppercase;
	font-style: italic;
	color: transparent;
	-webkit-text-stroke: 3px #18ccb3;
}

h2 {
	padding-top: 20px;
	font-size: 60px;
	text-transform: uppercase;
}
</style>
