<template>
	<section class="page page--landing page--news">
		<div class="page__inner">
			<article class="page__content">
				<p>This is news.</p>
			</article>
		</div>
	</section>
</template>

<script>
export default {
	name: 'News',
};
</script>
