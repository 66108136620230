<template>
	<section class="page page--landing page--holding">
		<div class="page__inner">
			<div class="page__xy grid-x grid-margin-x grid-margin-y">
				<div class="page__col medium-6 large-5 cell">
					<figure class="page__figure">
						<img src="/img/RecycleImage.svg" alt="" />
					</figure>
				</div>
				<div class="page__col medium-6 large-7 cell">
					<article class="page__content">
						<h1>Digital Solutions to eradicate single-use packaging</h1>
						<p>
							Full website coming soon, please drop us your e-mail if you'd like to join our press list and learn more about this exciting technology.
						</p>
					</article>
					<form class="form form--signup" ref="form" @submit.prevent="sendEmail" v-if="!showSuccess">
						<div class="grid-x grid-margin-y">
							<div class="cell">
								<input class="form__input" type="email" placeholder="Email" name="user_email" required />
								<button type="submit" class="btn btn--secondary">Submit</button>
							</div>
						</div>
					</form>
					<div v-else class="form__success">Thank you. We'll be in touch!</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
	name: 'Holding',
	data() {
		return {
			showSuccess: false,
		};
	},
	methods: {
		sendEmail() {
			emailjs.sendForm('service_jibqrvm', 'template_7w4vchf', this.$refs.form, 'user_I6pISKV96r6v2a8K8pIgP').then(
				result => {
					console.log('SUCCESS!', result.text);
					this.showSuccess = true;
				},
				error => {
					console.log('FAILED...', error.text);
					this.showSuccess = false;
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.page--holding {
	max-width: 1400px;
	margin: 0 auto;
}
</style>
